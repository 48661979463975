import React, { useState, useEffect, ChangeEvent } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import axios from 'axios'
import { apiUrl, marqueImgUrl } from '../../../_metronic/helpers/Helpers'
import { compressAndAppendImage} from '@mbs-dev/react-image-compressor'
import { frCustomeErrorNotify } from '@mbs-dev/react-helpers'
import { imageCompressor } from '@mbs-dev/react-image-compressor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'slugify';
import { CategoryProps, MarqueProps } from '../../../_metronic/helpers/Props'
import { useParams } from 'react-router-dom'
import useApiRequest from '@mbs-dev/api-request'

const Schema = Yup.object().shape({
})
const UpdateCategory: React.FC = () => {

  const { apiRequest } = useApiRequest()
  const [isloading, setIsLoading] = useState(false)
  const [isSubloading, setIsSubLoading] = useState(false)

  const { id } = useParams()
  const [newImage, setNewImage] = useState<File | null>(null)
  const [categorie, setCategorie] = useState<CategoryProps>()
  const [SubExist, setSubExist] = useState(false)
  const [parentTitle, setParentTitle] = useState()
  const [imagefile, setImage] = useState<File | any>(null)

  const [file, setFile] = useState<string>("")

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: `${apiUrl}/categories/${id}.json`,
        method: 'GET',
      })

      if (response.status === 200) {
        await setValues({
          libelle: response.data.libelle,
          imageCategory: response.data.imageCategory
        })
      }

    } catch {
      frCustomeErrorNotify()
    }
  }

  const formik = useFormik({
    initialValues: {
      libelle: "",
      imageCategory: "",
    },
    validationSchema:Schema,
    onSubmit: async (values, { resetForm }) => {

      try {
        setIsLoading(true)
        const formData = new FormData()
        const slug = generateSlug(values.libelle)
        formData.append('slug', slug)
        if (newImage) {
          const compressedImage = await imageCompressor(newImage, 0.4);
          const name = newImage as File
          const filename = name.name.replace(' ', '-').slice(0, name.name.lastIndexOf('.')) + '.webp';
          formData.append('imageCategory', compressedImage as File, filename)
        }
        const response = await axios.post(`${apiUrl}/update-category/${id}`, formData);
        
        if (response.status === 200) {
          setIsLoading(false)
          toast.success('Categorie est modifier avec succès'); 
        }else {
          toast.error('Erreur lors de la modification de Marque');
        }

      } catch {
        toast.error('Erreur lors de la communication avec le serveur');

      }

      resetForm()
    },
  })

  
  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setNewImage(e.target.files[0]);
    }
  };
  

  function generateSlug(inputString: string): string {
    let string = inputString.replace(/&/g, 'et').replace(/'|’/g, '-').replace('$', '');

    const transliterated = slugify(string, {
      lower: true,
      remove: /[^a-zA-Z0-9 -]/g,
    });

    const slug = transliterated.replace(/ /g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

    return slug;
  }


 



  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#product'
          aria-expanded='true'
          aria-controls='product'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Modifier Une Categorie</h3>
          </div>
        </div>

        <div id='product' className='collapse show'>
          <form onSubmit={handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>

              <div className='row mb-6'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nom De categorie</label>

                      <input
                        type='text'
                        name='title'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Nom de categorie'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.libelle}
                      />
                      {formik.touched.libelle && formik.errors.libelle && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.libelle}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Ancienne image</label>

                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-70px'>
                          <img
                            src={`${marqueImgUrl}/${values.imageCategory}`}
                            className='w-100 shadow'
                            alt=''
                          />
                        </div>
                      </div>
                    </div>

                    

                  </div>
                </div>
              </div>

              <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nouveau Image</label>
                  <input
                    type="file"
                    name="picture"
                    accept=".png, .jpg, .jpeg, .webp"
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    onChange={handleImageChange} />

                  {newImage ? (
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-70px'>
                        <img src={URL.createObjectURL(newImage)} alt='' className='w-100 shadow mt-2' />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                </div>


                {/* <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Image</label>
                  <input
                    type="file"
                    name="picture"
                    accept=".png, .jpg, .jpeg, .webp"
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target.files && e.target.files[0]) {
                        // Utilisez setFieldValue de Formik pour mettre à jour le champ "picture" avec le fichier sélectionné
                        formik.setFieldValue('picture', e.target.files[0]);
                      }
                    }}
                  />
                  {formik.touched.picture && formik.errors.picture && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.picture}</div>
                    </div>
                  )}
                </div> */}

            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={isloading}>
              {!isloading && 'Enregistrer les modifications'}
              {isloading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          </form>
        </div>

      </div>






    </>
  )
}

export default UpdateCategory