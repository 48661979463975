import React, { useEffect, useState } from 'react'
import {UserProps } from '../../../_metronic/helpers/Props'
import { apiUrl} from '../../../_metronic/helpers/Helpers'
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom'
import { Pagination } from '@mui/material';



const ListCommercial: React.FC = () => {

  const [isloading, setIsLoading] = useState(false)
  const [user, setUsers] = useState<UserProps[]>([])
  const [isLoadingData, setIsLoadingData] = useState(false)


   // Pagination state
   const [isFilter, setIsFilter] = useState(false)
   const [currentPage, setCurrentPage] = useState(1)
   const [totalPages, setTotalPages] = useState(1)
   const [filterCurrentPage, setFilterCurrentPage] = useState(1)
   const [filterTotalPages, setFilterTotalPages] = useState(1)
   const [defaultPage, setDefaultPage] = useState(1)
   const stored = localStorage.getItem('storedProdPage')
   useEffect(() => {
    if (stored) {
      setCurrentPage(Number(stored))
    }
   }, [])


   const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/users.jsonld`, {
        params: {
          page: currentPage,
          itemsPerPage: 10,
          roles: 'ROLE_COMMERCIALE'
        },
        headers: {
          Accept: 'application/ld+json'
        }
      })

      setUsers(response.data['hydra:member'])
      setTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10))
      setIsLoadingData(false)
    } catch {
    }
    }
     useEffect(() => {
     fetchData()
    }, [currentPage])

  const DeleteUser = async (userId: number) => {
    try {
      const response = await axios.delete(`${apiUrl}/users/${userId}`)

      if (response.status === 204) {
        setIsLoading(false)
        toast.success('Compte est supprimer avec succès');

        fetchData()
      } else {
        toast.error('Erreur lors de la supprission de Compte');
      }
    } catch {
        toast.error('Erreur lors de la communication avec le serveur');

    }
  }

  function handlePageChange(event: any, value: any) {
    setCurrentPage(value)
    localStorage.setItem('storedProdPage', String(value))
  }

  function handleFilterPageChange(event: any, value: any) {
    setFilterCurrentPage(value)
    localStorage.setItem('storedProdPage', String(value))
  }

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Liste Des commercial</span>
        </h3>
        {/* <div className='card-toolbar'>
          <Link to='/admin/AddUser' className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Nouveau Categorie
          </Link>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>

                <th className='ps-6 min-w-150px'>Code</th>
                <th className='ps-4 min-w-150px'>Nom et Prénom</th>
                <th className='ps-4 min-w-150px'>Email</th>
                <th className='ps-4 min-w-200px'>Téléphone</th>
                <th className='ps-4 min-w-200px'>adress</th>
                <th className='min-w-100px text-end rounded-end '>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {user.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <span className='text-info fw-bold d-block mb-1 ps-7 fs-6'>
                        {item.Code}
                      </span>
                    </td>
                    <td>
                      <span className='text-info fw-bold d-block mb-1 fs-6'>
                        {item.tiers}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item.email}
                      </span>
                    </td>
                    <td>
                       {
                          item.telPortable ?
                            <span className='text-dark text-primary d-block mb-1 fs-6'>
                              {item.telPortable}
                            </span>
                            :
                            <span className='badge badge-light-dark fs-8 p-2'>
                              Non ajouté
                            </span>
                        }
                    </td>
                    <td>
                      {
                          item.adresse ?
                            <span className='text-dark text-primary d-block mb-1 fs-6'>
                              {item.adresse}
                            </span>
                            :
                            <span className='badge badge-light-dark fs-8 p-2'>
                              Non ajouté
                            </span>
                        }
                     
                    </td>
                             
                    <td className='text-end'>
                        <Link to={`/admin/Update-Commercial/${item.id}`} className='btn btn-icon btn-light-primary btn-sm me-3'>
                          <i className='bi bi-pen-fill fs-4'></i>
                        </Link>
                      <button onClick={() => DeleteUser(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                        <i className='bi bi-trash-fill fs-4'></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      {
        isFilter
          ? <Pagination
            count={filterTotalPages}
            page={filterCurrentPage}
            color='primary'
            onChange={handleFilterPageChange}
            className='d-flex justify-content-center'
          />
          :
          <Pagination
            count={totalPages}
            page={currentPage}
            color='primary'
            onChange={handlePageChange}
            className='d-flex justify-content-center'
          />
      }
      {/* Start Filter Card */}
      <div className={`card mt-2 d-none`}>

        <div className='card-body p-1'>
          <div className="container">
            <div className="row justify-content-center align-items-center">

              <div className="col-4 money">
                <input
                  type="text"
                  placeholder='Numéro de page'
                  min={1}
                  defaultValue={defaultPage}
                  className='form-control form-control-lg form-control-dark'
                  onChange={(e) => {
                    const inputText = e.target.value
                    const numbersOnly = inputText.replace(/\D/g, '')
                    if (Number(numbersOnly) !== 0 && numbersOnly.length > 0 && Number(numbersOnly) <= Number(totalPages)) {
                      setCurrentPage(Number(numbersOnly))
                      if (isFilter) {
                        setFilterCurrentPage(Number(numbersOnly))
                      }
                      setDefaultPage(Number(numbersOnly))
                      localStorage.setItem('storedProdPage', numbersOnly)
                    }
                    e.target.value = numbersOnly
                  }} />
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ListCommercial