/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen025.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      />

      {/* ----------- Order Start   */}
      <AsideMenuItemWithSub
        to='/pages/Order'
        title='Commande'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/admin/ListOrder' title='list des commande' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* ----------- Order End  */}


      {/* ----------- Marque Start   */}
      <AsideMenuItemWithSub
        to='/pages/Marque'
        title='Marque'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        {/* <AsideMenuItem to='/admin/Add-Marque' title='Ajouter marque' hasBullet={true} /> */}
        <AsideMenuItem to='/admin/ListMarque' title='List des marques' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* ----------- Marque End  */}


      {/* ----------- Category Start   */}
      <AsideMenuItemWithSub
        to='/pages/Category'
        title='Categories'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        {/* <AsideMenuItem to='/admin/Add-Category' title='Ajouter categorie' hasBullet={true} /> */}
        <AsideMenuItem to='/admin/ListCategory' title='List des categories' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* ----------- Category End  */}


      {/* ----------- Product Start   */}
      <AsideMenuItemWithSub
        to='/pages/Product'
        title='Article'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        {/* <AsideMenuItem to='/admin/Add-Article' title='Ajouter article' hasBullet={true} /> */}
        <AsideMenuItem to='/admin/List-Article' title='list des article' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* ----------- Product End  */}

      {/* ----------- Slider Start   */}
      <AsideMenuItemWithSub
        to='/pages/Slider'
        title='Slider'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/admin/Add-Slider' title='Ajouter Slider' hasBullet={true} />
        <AsideMenuItem to='/admin/List-Slider' title='list des Slider' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* ----------- Slider End  */}

      {/* ----------- utilisateur Start   */}
       <AsideMenuItemWithSub
        to='/pages/User'
        title='Utilisateur'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/admin/List-Admin' title='list des utilisateur' hasBullet={true} />
        <AsideMenuItem to='/admin/List-Client' title='list des client' hasBullet={true} />
        <AsideMenuItem to='/admin/Add-User' title='Ajouter un utilisateur' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* ----------- utilisateur End  */}

      {/* ----------- commercial Start   */}
       <AsideMenuItemWithSub
        to='/pages/Commercial'
        title='Commercial'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/admin/List-Commercial' title='list des commercial' hasBullet={true} />
        <AsideMenuItem to='/admin/Add-Commercial' title='Ajouter un commercial' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* ----------- commercial End  */}


      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        icon='/media/icons/duotune/ecommerce/ecm007.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
      /> */}
      {/* <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
