export const apiUrl = process.env.REACT_APP_API_URL
export const uploadUrl = process.env.REACT_APP_API_UPLOAD
export const marqueImgUrl = `${uploadUrl}/marque_images`
export const categorieImgUrl = `${uploadUrl}/category_images`
export const articleImgUrl = `${uploadUrl}/article_images`
export const varianteImgUrl = `${uploadUrl}/variante_images`
export const userImgUrl = `${uploadUrl}/user_images`
export const zoneImgUrl = `${uploadUrl}/zone_images`
export const tableImgUrl = `${uploadUrl}/table_images`
export const sliderImgUrl = `${uploadUrl}/slider_images`

