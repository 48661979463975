import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { HiOutlineRefresh } from 'react-icons/hi'
import { OrderProps } from '../../../_metronic/helpers/Props'
import { apiUrl, varianteImgUrl } from '../../../_metronic/helpers/Helpers'
// import { Loading } from '../../../_metronic/helpers/Loading'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup'
import axios from 'axios'
import html2pdf from 'html2pdf.js'
import { deleteNotify, formatDate, frCustomeErrorNotify,exportDataToExcel} from '@mbs-dev/react-helpers'
import { Loading } from '../../../_metronic/helpers/Loading'
import useApiRequest from '@mbs-dev/api-request'
import { Pagination } from '@mui/material'
import OrderFacture from './OrderFacture'




const ListOrder: React.FC = () => {
  const { apiRequest } = useApiRequest()
  const [isloading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState<any | null>(null)
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [orders, setOrder] = useState<OrderProps[]>([])
  const [isPrintLoading, setIsPrintLoading] = useState(false)
  const [isFilterLoading, setIsFilterLoading] = useState(false)
  const [tiers, setTiers] = useState('')
  const [reference, setReference] = useState('')
  const [status, setStatus] = useState<null | string>(null)
  const [orderDate, setOrdersDate] = useState('')

  const { id } = useParams()

  // Pagination state
  const [isFilter, setIsFilter] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [filterCurrentPage, setFilterCurrentPage] = useState(1)
  const [filterTotalPages, setFilterTotalPages] = useState(1)
  const [inputFilter, setInputFilter] = useState(false)
  const [filterTotalItems, setFilterTotalItems] = useState(0)

  const initialOrderState = orders.map(order => ({
    ...order,
    isLoadingValidate: false,
    isLoadingAnnuler: false,
    isLoadingShipping: false,
    isPrinting: false,
  }))
  const [ordersWithLoading, setOrdersWithLoading] = useState(initialOrderState)

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/orders.jsonld`, {
        params: {
          page: currentPage,
          itemsPerPage: 10
        },
        headers: {
          Accept: 'application/ld+json'
        }
      })

      const updatedOrders = response.data['hydra:member'].map((order: any) => ({ ...order, isLoading: false }))

      // setOrders(response.data['hydra:member'])
      setOrdersWithLoading(updatedOrders)
      setTotalItems(response.data['hydra:totalItems'])
      setTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10))
    } catch {
      frCustomeErrorNotify()
    }
  }

  useEffect(() => {
    fetchData()
  }, [currentPage, totalItems])

  const changeOrderStatus = async (orderId: number, status: string) => {
    try {
      // setIsLoading(true)
      const data = { orderStatus: status }
      const response = await axios.put(`${apiUrl}/orders/${orderId}`, data);

      if (response.status === 200) {
        if (status === 'validate') {
          // setIsLoading(false)
          fetchData()
          toast.success("Commande bien validé")
        }
        if (status === 'shipping') {
          // setIsLoading(false)
          fetchData()
          toast.success("Commande en cours d'expédition")
        }
        if (status === 'annuler') {
          // setIsLoading(false)
          fetchData()
          toast.success("Commande bien annuler")
        }
      }

    } catch {
    }
  }


  function handlePageChange(event: any, value: any) {
    setCurrentPage(value)
  }

  function handleFilterPageChange(event: any, value: any) {
    setFilterCurrentPage(value)
  }

  const showLocation = (index: number) => {
    setSelectedOrder(ordersWithLoading[index])
    setShowModal(true)
  }

  const showDetails = (index: number) => {
    setSelectedOrder(ordersWithLoading[index])
    setShowDetailsModal(true)
  }

  const hideDetailsModal = () => {
    setShowDetailsModal(false)
  }

  const hideLocationModal = () => {
    setShowModal(false)
  }



  const filterData = async () => {
    setIsFilter(true)
    setIsFilterLoading(true)

    try {

      const params: Record<string, any> = {
        'user.tiers': tiers || '',
        page: filterCurrentPage,
        itemsPerPage: 10
      }

      if (status !== '') {
        params.orderStatus = status
      }
      if (orderDate !== '') {
        params.createdAt = orderDate
      }
      if (reference !== '') {
        params.reference = reference
      }

      const response = await axios.get(`${apiUrl}/orders.jsonld`, {
        params: params,
        headers: {
          Accept: 'application/ld+json'
        }
      })

      if (response.status === 200) {
        setIsFilterLoading(false)
        setOrdersWithLoading(response.data['hydra:member'])
        setFilterTotalItems(response.data['hydra:totalItems'])
        setFilterTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10))
      } else {
        frCustomeErrorNotify()
        setIsFilterLoading(false)
      }
    } catch {
      frCustomeErrorNotify()
      setIsFilterLoading(false)
    }
  }

  useEffect(() => {
    if (isFilter) {
      filterData()
    }
  }, [filterCurrentPage])

  useEffect(() => {
    if (status !== null || tiers !== '' || reference !== '' || orderDate !== '') {
      setInputFilter(true)
    }
  }, [status, tiers, reference, orderDate])

  const resetFilter = () => {
    setStatus(null)
    setOrdersDate('')
    setTiers('')
    setReference('')
    setIsFilter(false)
    setInputFilter(false)
    setCurrentPage(1)
    setFilterCurrentPage(1)
    fetchData()
    // setIsLoading(false)
    // setOrdersWithLoading(null)
  }

    const exportData = async () => {
      const response = await apiRequest({
        route: `${apiUrl}/export-for-update`,
        method: 'GET',
      });
      if (response.status === 200) {
        await exportDataToExcel(response.data, 'UserData' )
      }
    }



  function generatePDF(reference: number, index: number) {
    setIsPrintLoading(true)

    const updatedOrders = [...ordersWithLoading]
    updatedOrders[index].isPrinting = true
    setOrdersWithLoading(updatedOrders)

    const element = document.getElementById(`canvas_div_pdf${reference}`);

    if (element) {
      html2pdf(element, {
        margin: 5,
        filename: `C-${reference}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 192,
          letterRendering: true,
        },
        jsPDF: { unit: 'mm', orientation: 'portrait' },
        // jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        // jsPDF: { unit: 'mm', format: 'legal', orientation: 'portrait' },
      }).then(function (pdf: { save: () => void }) {
        setIsPrintLoading(false)

        const updatedOrders = [...ordersWithLoading]
        updatedOrders[index].isPrinting = false
        setOrdersWithLoading(updatedOrders)

        pdf.save()
      })
    }
  }

  const DeleteOrder = async (order: number) => {
    try {
      const response = await apiRequest({
        route: `${apiUrl}/orders/${order}`,
        method: 'DELETE',
      })
      if (response.status === 204) {
        deleteNotify('Commande')
        fetchData()
      }
    } catch {
      frCustomeErrorNotify()
    }
  }
  return (
    <>

      {/* Start Filter Card */}
      <div className={`card mb-4 py-5`}>

        <div className='card-body py-3'>
          <div className="container">
            <div className="row justify-content-start align-items-center">

              {/* Start Client Name */}
              <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  N° de commande
                </label>
                <input
                  className="form-control "
                  type="search" aria-label="Search"
                  placeholder='N° de commande'
                  value={reference}
                  onChange={(e) => {
                    setReference(e.target.value)
                  }} />

              </div>
              {/* End Client Name */}

              {/* Start Client Name */}
              <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Nom de client
                </label>
                <input
                  className="form-control "
                  type="search" aria-label="Search"
                  placeholder='Nom de Client'
                  value={tiers}
                  onChange={(e) => {
                    setTiers(e.target.value)
                  }} />

              </div>
              {/* End Client Name */}

              {/* Start Order Date */}
              <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Date de commande
                </label>
                <input
                  type='date'
                  name='dateDebut'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date commande'
                  onChange={(e) => setOrdersDate(e.target.value)}
                  value={orderDate}
                />
              </div>
              {/* End Order Date */}

              {/* Start Status */}
              <div className="col-md-6 col-lg-6 col-xl-6 col-12">
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                  Statut de commande
                </label>
                <div className='form-check form-check-custom form-check-solid form-check-info'>

                  {/* Validate */}
                  <input
                    type='checkbox'
                    name='status'
                    id='validate'
                    className='form-check-input scale-1'
                    checked={status === 'validate'}
                    onChange={() => setStatus('validate')}
                    value={'validate'}
                  />
                  <label htmlFor='validate' className='ms-3 col-form-label fw-bold fs-5'>
                    Validé
                  </label>

                  {/* Pending */}
                  <input
                    type='checkbox'
                    name='status'
                    id='pending'
                    className='form-check-input ms-4 scale-1'
                    checked={status === 'pending'}
                    onChange={() => setStatus('pending')}
                    value={'pending'}
                  />
                  <label htmlFor='pending' className='ms-3 col-form-label fw-bold fs-5'>
                    En cours
                  </label>


                  {/* shipping */}
                  <input
                    type='checkbox'
                    name='status'
                    id='shipping'
                    className='form-check-input ms-4 scale-1'
                    checked={status === 'shipping'}
                    onChange={() => setStatus('shipping')}
                    value={'shipping'}
                  />
                  <label htmlFor='shipping' className='ms-3 col-form-label fw-bold fs-5'>
                    Expédier
                  </label>


                  {/* Annuler */}
                  <input
                    type='checkbox'
                    name='status'
                    id='annuler'
                    className='form-check-input ms-4 scale-1'
                    checked={status === 'annuler'}
                    onChange={() => setStatus('annuler')}
                    value={'annuler'}
                  />
                  <label htmlFor='annuler' className='ms-3 col-form-label fw-bold fs-5'>
                    Annuler
                  </label>

                </div>
              </div>
              {/* End Status */}

              {/* Start Apply Filter */}
              <div className="row">
                <div className="col-md-6 col-lg-6 col-xl-2 col-12">
                  {
                    !isFilterLoading ?
                      <>
                        <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                        </label>
                        <button className='btn btn-light-primary me-3 fw-bold' onClick={filterData} disabled={!inputFilter} >
                          <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                          Filtrer
                        </button>
                      </>

                      :
                      <div className="me-3 mt-4 d-flex justify-content-start">
                        <Loading />
                      </div>
                  }
                </div>
              </div>
              {/* End Apply Filter */}

              {/* Start Reset Filter */}
              <div className="reset-filter ">
                <div className="ico" onClick={resetFilter}>
                  <HiOutlineRefresh className='filter-icon sshadow' />
                </div>
              </div>
              {/* End Reset Filter */}

            </div>
          </div>
        </div>

      </div>
      {/* Ens Filter Card */}
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Liste Des commandes</span>
          </h3>
          

          <button  
                           type={"submit"}
                           title="save"
                           className='card-toolbar btn btn-sm btn-light-primary bi bi-cloud-download-fill' 
                           onClick={exportData}>
                           Ficher Excel
         </button>
             

        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-100px rounded-start'>N° Commande</th>
                  <th className='ps-4 min-w-150px rounded-start'>Nom client</th>
                  <th className='min-w-100px'>Date De Commande</th>
                  <th className='ps-4 min-w-100px'>Prix Total</th>
                  <th className='min-w-100px'>Code commercial</th>
                  <th className='min-w-200px text-center rounded-end pe-4'>Action</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {ordersWithLoading.map((item, index) => {
                  return (
                    <tr key={item.id}>

                      <td>
                        <span className='text-dark fw-bold d-block ps-5 mb-1 fs-6'>
                          {item.reference}
                        </span>
                      </td>

                      <td>
                        {
                          item.user ?
                            <span className='text-dark fw-bold d-block ps-5 mb-1 fs-6'>
                              {item.user.tiers}
                            </span>
                            :
                            <span className='badge badge-light-dark fs-8 p-2'>
                              nouveau client
                            </span>
                        }
                      </td>

                      <td>
                        <span className='text-dark fw-bold d-block mb-1 ps-5 fs-6'>
                          {formatDate(item.createdAt)}
                        </span>
                      </td>

                      <td>
                        <span className='text-dark fw-bold d-block mb-1 ps-5 fs-6'>
                          {item.totalPrice} DH
                        </span>
                      </td>


                      <td>
                        <span className='text-dark fw-bold d-block mb-1 ps-10 fs-6'>
                          {item.codeCommerciale}
                        </span>
                      </td>


                      <td className='text-dark d-none'>
                        <span className='text-dark fw-bold d-block mb-1 fs-6'>

                          {item.orderStatus === 'pending' && (
                            <span className='badge badge-light-primary fs-6 fw-bold px-2'>
                              En cours
                            </span>
                          )}
                          {item.orderStatus === 'shipping' && (
                            <span className='badge badge-light-info fs-6 fw-bold px-4'>
                              Expédier
                            </span>
                          )}
                          {item.orderStatus === 'annuler' && (
                            <span className='badge badge-light-warning fs-6 fw-bold px-4'>
                              Annuler
                            </span>
                          )}

                          {item.orderStatus === 'validate' && (
                            <span className='badge badge-light-success fs-6 fw-bold px-4'>
                              Validé
                            </span>
                          )}

                        </span>
                      </td>


                      <td className='text-end d-non'>

                        {!false &&
                          <>
                            {
                              !item.isLoadingShipping && !item.isLoadingValidate && !item.isLoadingAnnuler ?
                                <div className="dropdown d-inline me-3">
                                  <button
                                    className="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    id="orderStatusDropdown"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    Status
                                  </button>
                                  <div className="dropdown-menu mt-2 px-3 py-2 w-100 text-center " aria-labelledby="orderStatusDropdown">
                                    {!item.isLoadingValidate ? (
                                      <button
                                        onClick={() => {
                                          changeOrderStatus(item.id, 'validate')
                                          const updatedOrders = [...ordersWithLoading]
                                          updatedOrders[index].isLoadingValidate = true
                                          setOrdersWithLoading(updatedOrders)
                                        }}
                                        className="mt-2 dropdown-item btn btn-success btn-sm me-1 fw-bold"
                                        disabled={item.orderStatus === 'validate'}
                                      >
                                        Valider
                                      </button>
                                    ) : (
                                      <button className="mt-2 dropdown-item btn btn-sm me-1 fw-bold" disabled>
                                        <Loading />
                                      </button>
                                    )}

                                    {!item.isLoadingShipping ? (
                                      <button
                                        onClick={() => {
                                          changeOrderStatus(item.id, 'shipping')
                                          const updatedOrders = [...ordersWithLoading]
                                          updatedOrders[index].isLoadingShipping = true
                                          setOrdersWithLoading(updatedOrders)
                                        }}
                                        className="mt-2 dropdown-item btn btn-info btn-sm me-1 fw-bold"
                                        disabled={item.orderStatus === 'shipping'}
                                      >
                                        Expédition
                                      </button>
                                    ) : (
                                      <button className="mt-2 dropdown-item btn btn-sm me-3 fw-bold" disabled>
                                        <Loading />
                                      </button>
                                    )}

                                    {!item.isLoadingAnnuler ? (
                                      <button
                                        onClick={() => {
                                          changeOrderStatus(item.id, 'annuler')
                                          const updatedOrders = [...ordersWithLoading]
                                          updatedOrders[index].isLoadingAnnuler = true
                                          setOrdersWithLoading(updatedOrders)
                                        }}
                                        className="mt-2 dropdown-item btn btn-warning btn-sm me-3 fw-bold"
                                        disabled={item.orderStatus === 'annuler'}
                                      >
                                        Annuler
                                      </button>
                                    ) : (
                                      <button className="mt-2 dropdown-item btn btn-sm me-3 fw-bold" disabled>
                                        <Loading />
                                      </button>
                                    )}
                                  </div>
                                </div>

                                :
                                <button className='btn btn-sm me-1 fw-bold'>
                                  <Loading />
                                </button>

                            }
                          </>
                        }

                        {!true &&
                          <>
                            {
                              !item.isLoadingValidate ?
                                <button
                                  onClick={() => {
                                    changeOrderStatus(item.id, 'validate')
                                    const updatedOrders = [...ordersWithLoading]
                                    updatedOrders[index].isLoadingValidate = true
                                    setOrdersWithLoading(updatedOrders)
                                  }}
                                  className='btn btn-success btn-sm me-1 fw-bold'
                                  disabled={item.orderStatus === 'validate'}
                                >
                                  Valider
                                </button>
                                :
                                <button className='btn btn-sm me-1 fw-bold'>
                                  <Loading />
                                </button>
                            }

                            {
                              !item.isLoadingShipping ?
                                <button
                                  onClick={() => {
                                    changeOrderStatus(item.id, 'shipping')
                                    const updatedOrders = [...ordersWithLoading]
                                    updatedOrders[index].isLoadingShipping = true
                                    setOrdersWithLoading(updatedOrders)
                                  }}
                                  className='btn btn-info btn-sm me-1 fw-bold'
                                  disabled={item.orderStatus === 'shipping'}
                                >
                                  Expédition
                                </button>
                                :
                                <button className='btn btn-sm me-3 fw-bold'>
                                  <Loading />
                                </button>
                            }

                            {!item.isLoadingAnnuler ?
                              <button
                                onClick={() => {
                                  changeOrderStatus(item.id, 'annuler')
                                  const updatedOrders = [...ordersWithLoading]
                                  updatedOrders[index].isLoadingAnnuler = true
                                  setOrdersWithLoading(updatedOrders)
                                }}
                                className='btn btn-warning btn-sm me-3 fw-bold'
                                disabled={item.orderStatus === 'annuler'}
                              >
                                Annuler
                              </button>
                              :
                              <button className='btn btn-sm me-3 fw-bold'>
                                <Loading />
                              </button>
                            }
                          </>
                        }

                        {/* <button onClick={() => showDetails(index)} className='btn btn-icon btn-light-info btn-sm  me-3'>
                          <i className='bi bi-eye-fill fs-4'></i>
                        </button> */}
                      
                        <Link to={`/order/order-details/${item.id}`} className='d-none btn btn-icon btn-light-info btn-sm  me-2'>
                          <i className='bi bi-eye-fill fs-4'></i>
                        </Link>

                        {/* <button onClick={() => showLocation(index)} className='btn btn-icon btn-light-primary btn-sm  me-3'>
                          <i className='bi bi-geo-alt fs-4'></i>
                        </button> */}


                        {!item.isPrinting ? (
                          <button
                            className="btn btn-icon btn-light-dark btn-sm me-3"
                            onClick={() => generatePDF(item.reference, index)}
                          >
                            <i className="bi bi-printer-fill fs-4"></i>
                          </button>
                        ) : (
                          <button className='btn btn-sm me-1 fw-bold'>
                            <Loading />
                          </button>
                        )}

                        <div className="d-none">
                          <div id={`canvas_div_pdf${item.reference}`} className=''>
                            <OrderFacture order={item} />
                          </div>
                        </div>

                        <button onClick={() => DeleteOrder(item.id)} className='btn btn-icon btn-light-danger btn-sm me-2'>
                          <i className='bi bi-trash-fill fs-4'></i>
                        </button>
                      </td>

                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        {
          isFilter
            ?
            <Pagination
              count={filterTotalPages}
              page={filterCurrentPage}
              color='primary'
              onChange={handleFilterPageChange}
              className='d-flex justify-content-center'
            />
            :
            <Pagination
              count={totalPages}
              page={currentPage}
              color='primary'
              onChange={handlePageChange}
              className='d-flex justify-content-center'
            />

        }
      </div>


    </>
  )
}

export default ListOrder