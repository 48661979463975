import React, { useState, useEffect, ChangeEvent } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { apiUrl, articleImgUrl } from '../../../_metronic/helpers/Helpers'
import { ArticleProps } from '../../../_metronic/helpers/Props'
import { compressAndAppendImage } from '@mbs-dev/react-image-compressor'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'slugify';
import useApiRequest from '@mbs-dev/api-request'
import { imageCompressor } from '@mbs-dev/react-image-compressor';




const UpdateArticle: React.FC = () => {

  const [isloading, setIsLoading] = useState(false)
  const [isloadingData, setIsLoadingData] = useState(false)
  const [isSubloading, setIsSubLoading] = useState(false)

  const { id } = useParams()
 
  const [newImage, setNewImage] = useState<File | null>(null)
  const [product, setProducts] = useState<ArticleProps>()
  const [SubExist, setSubExist] = useState(false)
  const [parentTitle, setParentTitle] = useState()
  const [imagefile, setImage] = useState<File | any>(null)

  const [file, setFile] = useState<string>("")
  const { apiRequest } = useApiRequest()


  const Schema = Yup.object().shape({
    designation: Yup.string(),
    // picture: Yup.string(),
    prix: Yup.number(),
    codeArticle: Yup.string(),
    disponibility: Yup.string(),
  })

  const fetchData = async () => {

    setIsLoadingData(true)

    // const response = await axios.get(`${apiUrl}/articles/${id}`);


    const response = await apiRequest({
      route: `${apiUrl}/articles/${id}`,
      method: 'GET'
    })


    if (response.status === 200) {
      await setValues({
        designation: response.data.designation,
        imageArticle: response.data.imageArticle,
        prix: response.data.prix,
        codeArticle: response.data.codeArticle,
        disponibility: response.data.disponibility,

      })

      setProducts(response.data)
      setIsLoadingData(false)
    }
  }


  useEffect(() => {
    fetchData()
  }, [])


  const formik = useFormik({
    initialValues: {
      designation: "",
      prix: "",
      imageArticle: "",
      codeArticle: "",
      disponibility: "",
    },


    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true)
        const formData = new FormData()
        const slug = generateSlug(values.designation)
        formData.append('slug', slug)
        if (newImage) {
          const compressedImage = await imageCompressor(newImage, 0.4);
          const name = newImage as File
          const filename = name.name.replace(' ', '-').slice(0, name.name.lastIndexOf('.')) + '.webp';
          formData.append('imageArticle', compressedImage as File, filename)
        }

        // const response = await axios.post(`${apiUrl}/update-article/${id}`, formData)

        const response = await apiRequest({
          route: `${apiUrl}/update-article/${id}`,
          method: 'POST',
          data: formData
        })


        if (response.status === 200) {
          setIsLoading(false)
          toast.success('Article est enregistré avec succès');

        }
        else {
          toast.error('Erreur lors de l\'enregistrement de article');
        }
      } catch (error) {
        toast.error('Erreur lors de la communication avec le serveur');
      }

      // resetForm()

    },
  })


  function generateSlug(inputString: string): string {
    let string = inputString.replace(/&/g, 'et').replace(/'|’/g, '-').replace('$', '');

    const transliterated = slugify(string, {
      lower: true,
      remove: /[^a-zA-Z0-9 -]/g,
    });

    const slug = transliterated.replace(/ /g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

    return slug;
  }


  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setNewImage(e.target.files[0]);
    }
  };


  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#product'
          aria-expanded='true'
          aria-controls='product'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Modifier Un Article</h3>
          </div>
        </div>

        <div id='product' className='collapse show'>
          <form onSubmit={handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>

              <div className='row mb-6'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nom De Article</label>

                      <input
                        type='text'
                        name='designation'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Nom de article'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.designation}
                      />
                      {formik.touched.designation && formik.errors.designation && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.designation}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Ancienne image</label>

                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-70px'>
                          <img
                            src={`${articleImgUrl}/${values.imageArticle}`}
                            className='w-100 shadow'
                            alt=''
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>

                <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nouveau Image</label>
                  <input
                    type="file"
                    name="imageArticles"
                    accept=".png, .jpg, .jpeg, .webp"
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    onChange={handleImageChange} />

                  {newImage ? (
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-70px'>
                        <img src={URL.createObjectURL(newImage)} alt='' className='w-100 shadow mt-2' />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                </div>
              </div>


              <div className='row'>
                <div className='col-lg-4 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Code Article</label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='codeArticle'
                    {...formik.getFieldProps('codeArticle')}
                  />
                  {formik.touched.codeArticle && formik.errors.codeArticle && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.codeArticle}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-4 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>prix</label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='prix'
                    {...formik.getFieldProps('prix')}
                  />
                  {formik.touched.prix && formik.errors.prix && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.prix}</div>
                    </div>
                  )}
                </div>

              </div>

            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={isloading}>
                {!isloading && 'Save Changes'}
                {isloading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>


        </div>

      </div>






    </>
  )
}

export default UpdateArticle