import React, { useEffect, useState } from 'react'
import {UserProps } from '../../../_metronic/helpers/Props'
import { apiUrl} from '../../../_metronic/helpers/Helpers'
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom'

import {formatDate } from '@mbs-dev/react-helpers'


const ListClient: React.FC = () => {

  const [isloading, setIsLoading] = useState(false)
  const [user, setUsers] = useState<UserProps[]>([])


  const fetchData = async () => {
    try {
        const response = await axios.get(`${apiUrl}/users.json`);
        console.log('API Response:', response.data);
        const clients = response.data.filter((user: UserProps) => user.roles.includes('ROLE_COMMERCIAL'));
        setUsers(clients);

    } catch {
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  const DeleteUser = async (userId: number) => {
    try {
      const response = await axios.delete(`${apiUrl}/users/${userId}`)

      if (response.status === 204) {
        setIsLoading(false)
        toast.success('Compte est supprimer avec succès');

        fetchData()
      } else {
        toast.error('Erreur lors de la supprission de Compte');
      }
    } catch {
        toast.error('Erreur lors de la communication avec le serveur');

    }
  }


  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Liste Des Client</span>
        </h3>
        {/* <div className='card-toolbar'>
          <Link to='/admin/AddUser' className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Nouveau Categorie
          </Link>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>

                <th className='ps-4 min-w-150px'>Code client</th>
                <th className='ps-4 min-w-150px'>Nom et Prénom</th>
                <th className='ps-4 min-w-150px'>Email</th>
                <th className='ps-4 min-w-150px'>Téléphone</th>
                <th className='ps-4 min-w-200px'>adress</th>
                <th className='ps-4 min-w-150px'>Date Création</th>
                <th className='ps-4 min-w-150px'>Status</th>
                <th className='min-w-100px text-end rounded-end pe-5'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {user.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <span className='text-info fw-bold d-block mb-1 ps-7 fs-6'>
                        {item.codecommercial}
                      </span>
                    </td>
                    <td>
                      <span className='text-info fw-bold d-block mb-1 fs-6'>
                        {item.tiers}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item.email}
                      </span>
                    </td>
                    <td>
                      <span className='text-info fw-bold d-block mb-1 fs-6'>
                        {item.telPortable}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item.adresse}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item.datecre}
                      </span>
                    </td>
                    <td>
                        <span className='text-dark fw-bold  d-block mb-1 fs-6'>

                          {item.status === 'valider' && (
                            <span className='badge badge-light-primary fs-6 fw-bold px-4'>
                              valider
                            </span>
                          )}
                          {item.status === 'nonValider' && (
                            <span className='badge badge-light-info fs-6 fw-bold px-4'>
                              nouveau
                            </span>
                          )}

                        </span>
                      </td>
                    
                    <td className='text-end'>
                      <Link to={`/admin/Update-User/${item.id}`} className='btn btn-icon btn-light-primary btn-sm me-3'>
                          <i className='bi bi-pen-fill fs-4'></i>
                        </Link>
                      <button onClick={() => DeleteUser(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                        <i className='bi bi-trash-fill fs-4'></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default ListClient