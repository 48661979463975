import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { ArticleProps } from '../../../_metronic/helpers/Props'
import { apiUrl, articleImgUrl} from '../../../_metronic/helpers/Helpers'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './switch.css'
import { Pagination } from '@mui/material';


const ListArticle: React.FC = () => {

  
  const [isloading, setIsLoading] = useState(false)
  const [article, setArticles] = useState<ArticleProps[]>([])
  const [toggle, setToggle] = useState(false);
  const { id } = useParams();
  const [isLoadingData, setIsLoadingData] = useState(false)

  // Pagination state
  const [isFilter, setIsFilter] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [filterCurrentPage, setFilterCurrentPage] = useState(1)
  const [filterTotalPages, setFilterTotalPages] = useState(1)
  const [defaultPage, setDefaultPage] = useState(1)

  const stored = localStorage.getItem('storedProdPage')
  useEffect(() => {
    if (stored) {
      setCurrentPage(Number(stored))
    }
  }, [])


  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/articles.jsonld`, {
        params: {
          page: currentPage,
          itemsPerPage: 10
        },
        headers: {
          Accept: 'application/ld+json'
        }
      })

      setArticles(response.data['hydra:member'])
      setTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10))
      setIsLoadingData(false)
    } catch {
    }
  }
  useEffect(() => {
    fetchData()
  }, [currentPage])

  function handlePageChange(event: any, value: any) {
    setCurrentPage(value)
    localStorage.setItem('storedProdPage', String(value))
  }

  function handleFilterPageChange(event: any, value: any) {
    setFilterCurrentPage(value)
    localStorage.setItem('storedProdPage', String(value))
  }




  const DeleteProducts = async (productId: number) => {
    try {
      const response = await axios.delete(`${apiUrl}/articles/${productId}`)

      if (response.status === 204) {
        setIsLoading(false)
        toast.success('Article est supprimer avec succès');

        // fetchData()
      }
      else {
        toast.error('Erreur lors de la supprission de article');
      }
    } catch {
      toast.error('Erreur lors de la communication avec le serveur');

    }

  }

  

  
  const changeDisponibilite = async (articleId: number, disponibility: string) => {
    try {

      const formData = new FormData()
      formData.append('id', String(articleId))
      formData.append('disponibility', disponibility)

      const response = await axios.post(`${apiUrl}/update-article/${articleId}`,formData); 

      if (response.status === 200) {
        if (disponibility === 'enstock') {
          toast.success("Le article a été mis en enstock ")
          fetchData()
        }
        if (disponibility === 'rupture') {
          toast.error("Le article a été mis en rupture")
          fetchData()

        }
      }

    } catch {
      toast.error('Erreur lors de la communication avec le serveur');

    }
  }
  
 
  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Liste Des articles</span>
        </h3>
        <div className='card-toolbar'>
          <Link to='/admin/AddProduct' className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Nouveau Article
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */} 
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-125px rounded-start'>Picture</th>
                <th className='ps-4 min-w-150px'>nom article</th>
                <th className='ps-4 min-w-150px'>code Article</th>
                <th className='ps-4 min-w-100px'>Prix</th>
                <th className='ps-4 min-w-125px'>Disponibilité</th>
                <th className='min-w-150px text-end rounded-end pe-5'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {article.map((item, index) => {
                      return (
                        <tr key={item.id}>
                    <td>
                      <div className='symbol symbol-70px'>
                      {Array.isArray(item.imageArticle) && item.imageArticle.length > 0 && (
                        <>
                              <img
                                src={`${item.imageArticle[0].picture}`} 
                                alt=''
                                className='w-100 shadow'
                                /></>
                            )}
                      </div>
                    </td>

                    <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item.codeArticle}
                      </span>
                    </td>

                    <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item.designation}
                      </span>
                    </td>

                    <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item.prix}
                      </span>
                    </td>
                   

                           <td className=''>
                            {item.disponibility === 'enstock' ?
                              <div className='form-check form-switch form-switch-sm form-check-custom form-check-success '>
                                <input
                                  className='form-check-input form-check-input-true'
                                  type='checkbox'
                                  value='rupture'
                                  name='disponibilite'
                                  onChange={() => changeDisponibilite(item.id, 'rupture')}
                                  checked={item.disponibility === 'enstock' ? true : false}
                                />
                                <label className='form-check-label fw-bold'>En Stock</label>
                              </div>
                              :
                              <div className='form-check form-switch form-switch-sm form-check-custom form-check-danger'>
                                <input
                                  className='form-check-input form-check-input-false'
                                  type='checkbox'
                                  value='enstock'
                                  name='disponibilite'
                                  onChange={() => changeDisponibilite(item.id, 'enstock')}
                                  checked={item.disponibility === 'enstock'}
                                />
                                <label className='form-check-label fw-bold'>Rupture</label>
                              </div>
                            }
                          </td>
                    
                    <td className='text-end'>
                      <Link to={`/admin/Update-Article/${item.id}`} className='btn btn-icon btn-light-primary btn-sm me-3'>
                        <i className='bi bi-pen-fill fs-4'></i>
                      </Link>
                      <button onClick={() => DeleteProducts(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                        <i className='bi bi-trash-fill fs-4'></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {
        isFilter
          ? <Pagination
            count={filterTotalPages}
            page={filterCurrentPage}
            color='primary'
            onChange={handleFilterPageChange}
            className='d-flex justify-content-center'
          />
          :
          <Pagination
            count={totalPages}
            page={currentPage}
            color='primary'
            onChange={handlePageChange}
            className='d-flex justify-content-center'
          />
      }
      {/* Start Filter Card */}
      <div className={`card mt-2 d-none`}>

        <div className='card-body p-1'>
          <div className="container">
            <div className="row justify-content-center align-items-center">

              <div className="col-4 money">
                <input
                  type="text"
                  placeholder='Numéro de page'
                  min={1}
                  defaultValue={defaultPage}
                  className='form-control form-control-lg form-control-dark'
                  onChange={(e) => {
                    const inputText = e.target.value
                    const numbersOnly = inputText.replace(/\D/g, '')
                    if (Number(numbersOnly) !== 0 && numbersOnly.length > 0 && Number(numbersOnly) <= Number(totalPages)) {
                      setCurrentPage(Number(numbersOnly))
                      if (isFilter) {
                        setFilterCurrentPage(Number(numbersOnly))
                      }
                      setDefaultPage(Number(numbersOnly))
                      localStorage.setItem('storedProdPage', numbersOnly)
                    }
                    e.target.value = numbersOnly
                  }} />
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ListArticle