import React, { useState, ChangeEvent } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import axios from 'axios'
import { apiUrl } from '../../../_metronic/helpers/Helpers'
import { compressAndAppendImage} from '@mbs-dev/react-image-compressor'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'slugify';



const AddCategory: React.FC = () => {

  const [loading, setIsLoading] = useState(false)
  const [imageFile, setimageFile] = useState<File>()

  const Schema = Yup.object().shape({
    title: Yup.string().required('Le nom de categorie est obligatoire'),
    picture: Yup.string().required("L'picture de categorie est obligatoire"),

  })

  const formik = useFormik({
    initialValues: {
      libelle: "",
      imageCategory: "",
      slug: ""

    },


    validationSchema: Schema,
    onSubmit: async (values) => {
      // setIsLoading(true)
     
      const formData = new FormData()
      formData.append('libelle', values.libelle)
      await compressAndAppendImage(values.imageCategory as unknown as File, formData, 'imageCategory', 0.4)
      const slug = generateSlug(values.libelle)
      formData.append('slug', slug)

      try {
        const response = await axios.post(`${apiUrl}/categories`, formData);
    
        if (response.status === 200) {
          setIsLoading(false)
          toast.success('Categorie est enregistré avec succès');
        } 
        else {
          toast.error('Erreur lors de l\'enregistrement de Categorrie');
        }
      } catch (error) {
        toast.error('Erreur lors de la communication avec le serveur');
      }




    },
  })


  function generateSlug(inputString: string): string {
    let string = inputString.replace(/&/g, 'et').replace(/'|’/g, '-').replace('$', '');

    const transliterated = slugify(string, {
      lower: true,
      remove: /[^a-zA-Z0-9 -]/g,
    });

    const slug = transliterated.replace(/ /g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

    return slug;
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#category'
        aria-expanded='true'
        aria-controls='category'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ajouter Une Categorie</h3>
        </div>
      </div>

      <div id='category' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>

          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nom de categorie</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Nom de categorie'
                      {...formik.getFieldProps('libelle')}
                    />
                    {formik.touched.libelle && formik.errors.libelle && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.libelle}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>picture</label>
                    <input
                      type="file"
                      name="picture"
                      accept=".png, .jpg, .jpeg, .webp"
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {

                        if (e.target.files && e.target.files[0]) {
                          // setFile(URL.createObjectURL(e.target.files[0]))
                          // setpicture(e.target.files[0])
                          formik.setFieldValue('imageCategory', e.target.files[0])
                        }
                      }} />
                    {formik.touched.imageCategory && formik.errors.imageCategory && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.imageCategory}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

        </form>
      </div>
    </div>
  )
}

export default AddCategory