import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { formatDate, formatPrice } from '@mbs-dev/react-helpers'

import './OrderItem.css'
import { OrderItemProps, OrderProps } from '../../../_metronic/helpers/Props'
interface Order {
  order: OrderProps
}

const OrderFacture: React.FC<Order> = (({ order }) => {

  return (
    <div className="bh-container d-flexbh-justify-content-center">
      <div className={`card bh-card hbcontainer d-flex bh-justify-content-center `}>
        <div>
          <span className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-2 mb-1'><img src={toAbsoluteUrl('/media/logos/global-dash-logo.png')} alt='' className='bh-logo' /></span>
          </span>
        </div>
        <div className='card-body py-3'>
          <div className="hbcontainer">

            <div className="row bh-m-row">
              <div className="bh-col-1 col-6 bh-logo-container">
                <img src={toAbsoluteUrl('/media/logos/AlinaAppIcon.png')} alt='' className='bh-logo' />
              </div>
              <div className={`col-6 d-flex bh-justify-content-end align-items-`}>

                <div className="bh-order-info">
                  <div>
                    <p className='bh-p-1'>
                      <span className='fw-bold bh-info-text'>Numero de commande : </span>
                      <span className='bh-info-value'>{order?.reference} </span>
                    </p>
                    <p className='bh-p-1'>
                      <span className='fw-bold bh-info-text'>Numero de commercial : </span>
                      <span className='bh-info-value'>{order.codeCommerciale} </span>
                    </p>
                    <p className='bh-p-1'>
                      <span className='fw-bold bh-info-text'>Date Commande : </span>
                      <span className='bh-info-value'>{formatDate(order?.createdAt)}</span>
                    </p>
                    
                  </div>

                </div>
              </div>
            </div>

            <div className="row row-user">
              <hr className='hrtag' />
              <div className="col-12  bh-m-client ">
                <div className="bh-user-info mt-">
                  {/* <p className='fw-bold bh-user-info-client'><span>Client :</span></p> */}
                  <p className='bh-p-2'>
                    <span className='fw-bold bh-info-text'>Nom : </span>
                    <span className='bh-info-value'>{order.user ? order.user.tiers : ''}</span>
                  </p>
                  <p className='bh-p-2'>
                    <span className='fw-bold bh-info-text'>Telephone : </span>
                    <span className='bh-info-value'>{order.user ? order.user.telPortable : ''}</span>
                  </p>
                 
                </div>
              </div>

            </div>


          </div>
          {/* end container */}
          {/* <hr className='hrtag' /> */}
          <div className='table-responsive m-table'>
            <table className='table align-middle gs-0 gy-4' cellPadding={0} cellSpacing={0}>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  {/* <th className='ps-4  rounded-star tb-radius bh-t-dark'>Image</th> */}
                  <th className='ps-4  rounded-star tb-radius bh-t-dark text-align-left'>#Ref</th>
                  <th className='ps-4  rounded-star tb-radius bh-t-dark'>Designation</th>

                  <th className='bh-t-center bh-t-dark'>Qté</th>
                  <th className='ps-4 bh-txt-end bh-t-dark min-with-th-pu'>PU</th>
                  <th className='pl-0  bh-txt-end bh-t-dark total-min-w'>Total HT</th>
                </tr>
              </thead>
              <tbody>
                {order?.orderItems.map((o: OrderItemProps, index: number) => (
                  <tr key={o.id} className='item-row'>
                     <td className='bh-t-center bh-p-0' style={{textAlign:"left"}}>
                      <span className='fw--bold text-dark d-block fs-5'>
                      {o.article ? o.article.codeArticle : '--'}
                      </span>
                    </td>

                    <td className={`bh-p-0`}>
                      <span className='text-dark d-block ml-13 fs-order'>
                      {o.article ? o.article.designation : '--'}
                      </span>
                    </td>

                   

                    <td className='bh-t-center bh-p-0'>
                      <span className='fw--bold text-dark d-block fs-5'>
                        {o.quantity}
                      </span>
                    </td>

                    <td className='bh-t-end hbp-0 item-td'>
                      <span className='fw--bold text-dark d-block fs-7'>
                        {formatPrice(String(o.price))}
                      </span>
                    </td>

                    <td className='bh-t-end hbp-0 item-td'>
                      <span className='fw-bold text-dark d-block fs-7'>
                        {formatPrice(String(o.price * o.quantity))}
                      </span>
                    </td>

                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>


          <div className="hr-container">
            <div className="row">
              <hr className='hrtag' />

              <div className="col-12 d-flex bh-justify-content-end">
                <p className='p-w'>
                  <span className='fwbold bh-price-text'>TotalHT : </span>
                  <span className='bh-price-value fw-bold'>{formatPrice(String(order?.totalPrice - order?.totalPrice * 0.2 ))} MAD</span>
                </p>
              </div>

              <div className="col-12 d-flex bh-justify-content-end">
                <p className='p-w'>
                  <span className='fwbold bh-price-text'>Prix TVA (20 %):    </span>
                  <span className='bh-price-value fw-bold'>{Number(order?.totalPrice * 0.2)} MAD</span>
                </p>
              </div>

              <div className="col-12 d-flex bh-justify-content-end">
                <p className='p-w bh-total-price-p'>
                  <span className='fwbold bh-total-price'>TotalTTC  : </span>
                  <span className='bh-price-value fw-bold'>{Number(order?.totalPrice)} MAD</span>
                </p>
              </div>
            </div>

            {order?.orderNote ?
              <div className="row bh-mt-6">
                <div className="col-12 d-flex bh-justify-content-start">
                  <p className='fw-bold bh-user-info-client'><span>Remarque :</span></p>
                </div>

                <div className="col-12 d-flex bh-justify-content-start">
                  <p className='bh-line'>
                    <span className='fw-bold bh-pricetext bh-order-note'>{order?.orderNote} </span>
                  </p>
                </div>
              </div>
              :
              null
            }

          </div>
        </div>

      </div>

      <div className='cardfooter bh-card-footer bh-align-items-end fixedbottom d-flex cbh-justify-content-end py-6 px-9'>
        <div className="container">
          <hr className='hrtag' />
          <div className="row">
            <p className='bh-infotext bh-data  d-flex bh-justify-content-center bh-mb-10'>

              <span className='bh-data-text fw-bold'>I.C.E N° :</span>
              <span className='bh-data-value fw-bold'>000102471000014</span>

              <span className='separate fw-bold'> - </span>

              <span className='bh-data-text fw-bold'>RC N° :</span>
              <span className='bh-data-value fw-bold'>258723</span>

              <span className='separate fw-bold'> - </span>

              <span className='bh-data-text fw-bold'>Tel :</span>
              <span className='bh-data-value fw-bold'>+212 522 359 801</span>

              <span className='separate fw-bold'> - </span>

              <span className='bh-data-text fw-bold'>Fax :</span>
              <span className='bh-data-value fw-bold'>+212 522 664 678</span>
            </p>
          </div>

          <div className="row">
            <p className="bh-infotext bh-data  d-flex bh-justify-content-center">
              <span className='bh-data-text fw-bold'>Email :</span>
              <span className='bh-data-value fw-bold'>commercial@alina.ma</span>

              <span className='separate fw-bold'> - </span>

              <span className='bh-data-text fw-bold'>Adresse :</span>
              <span className='bh-data-value fw-bold'>Route Cotière 111, Km 11.500 Ain Sbaa(à coté de TOYOTA)-20000 </span>

            </p>
          </div>
        </div>
      </div>
    </div>
  )
})

export default OrderFacture