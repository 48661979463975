import React, { useState, ChangeEvent } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import axios from 'axios'
import { apiUrl } from '../../../_metronic/helpers/Helpers'
import { compressAndAppendImage } from '@mbs-dev/react-image-compressor'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'slugify';



const AddUser: React.FC = () => {
  
  const [loading, setIsLoading] = useState(false)
  const [imageFile, setimageFile] = useState<File>()

  const Schema = Yup.object().shape({
    firstname: Yup.string(),
    lastname: Yup.string(),
    email: Yup.string().required("L'email est obligatoire"),
    birthday: Yup.string().required("birthday obligatoire"),
    password: Yup.string().required("password obligatoire"),
    phoneNumber: Yup.string().required("password obligatoire"),

  })

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email:"",
      picture: "",
      birthday:"",
      password:"",
      phoneNumber:""

    },


    validationSchema: Schema,
    onSubmit: async (values) => {
      // setIsLoading(true)
     
      const formData = new FormData()
      formData.append('firstname', values.firstname)
      formData.append('lastname', values.lastname)
      formData.append('email', values.email)
      formData.append('birthday', values.birthday)
      formData.append('password', values.password)
      formData.append('phoneNumber', values.phoneNumber)
      if (values.picture) {
        // Si une nouvelle image est sélectionnée
        await compressAndAppendImage(values.picture as unknown as File, formData, 'picture', 0.4);
      }
      
      try {
        const response = await axios.post(`${apiUrl}/register-admin`, formData);
    
        if (response.status === 200) {
          setIsLoading(false)
          toast.success('Utilisateur enregistré avec succès');
        } 
        else {
          toast.error('Erreur lors de l\'enregistrement de l\'utilisateur');
        }
      } catch (error) {
        toast.error('Erreur lors de la communication avec le serveur');
      }




    },
  })


  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#category'
        aria-expanded='true'
        aria-controls='category'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ajouter Un Utilisateur</h3>
        </div>
      </div>

      <div id='category' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>

          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Prénom</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='firstname'
                      {...formik.getFieldProps('firstname')}
                    />
                    {formik.touched.firstname && formik.errors.firstname && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.firstname}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nom</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='lastname'
                      {...formik.getFieldProps('lastname')}
                    />
                    {formik.touched.lastname && formik.errors.lastname && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.lastname}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Téléphone</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='phoneNumber'
                      {...formik.getFieldProps('phoneNumber')}
                    />
                    {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.phoneNumber}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Email</label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='email'
                      {...formik.getFieldProps('email')}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.email}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>date de naissance</label>
                    <input
                      type='date'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='birthday'
                      {...formik.getFieldProps('birthday')}
                    />
                    {formik.touched.birthday && formik.errors.birthday && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.birthday}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>password</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='password'
                      {...formik.getFieldProps('password')}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.password}</div>
                      </div>
                    )}
                  </div>


                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>picture</label>
                    <input
                      type="file"
                      name="picture"
                      accept=".png, .jpg, .jpeg, .webp"
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {

                        if (e.target.files && e.target.files[0]) {
                          // setFile(URL.createObjectURL(e.target.files[0]))
                          // setpicture(e.target.files[0])
                          formik.setFieldValue('picture', e.target.files[0])
                        }
                      }} />
                    {formik.touched.picture && formik.errors.picture && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.picture}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

        </form>
      </div>
    </div>
  )
}

export default AddUser