import React, { useState, useEffect, ChangeEvent } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { apiUrl, categorieImgUrl, articleImgUrl, userImgUrl } from '../../../_metronic/helpers/Helpers'
import { UserProps } from '../../../_metronic/helpers/Props'
import { compressAndAppendImage} from '@mbs-dev/react-image-compressor'
import slugify from 'slugify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Schema = Yup.object().shape({
  firstname: Yup.string(),
  lastname: Yup.string(),
  email: Yup.string(),
  phoneNumber: Yup.number(),
  birthday: Yup.string(),
  password: Yup.string(),
})

const UpdateUser: React.FC = () => {

  const [isloading, setIsLoading] = useState(false)
  const [isSubloading, setIsSubLoading] = useState(false)

  const { id } = useParams()
  const [newImage, setNewImage] = useState<File | null>(null)
  const [user, setUser] = useState<UserProps>()
  const [SubExist, setSubExist] = useState(false)
  const [parentTitle, setParentTitle] = useState()
  const [imagefile, setImage] = useState<File | any>(null)

  const [file, setFile] = useState<string>("")

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/users/${id}`);

      setUser(response.data)
      await setValues({
        codecommercial: response.data.codecommercial,
        tiers: response.data.tiers,
        telPortable: response.data.telPortable,
        email: response.data.email,
        password: response.data.password,
        birthday: response.data.birthday,
        datecre: response.data.birthday
        
        
      })    
    } catch {
    }
  }
  useEffect(() => {
    fetchData()
  }, [])


  const formik = useFormik({
    initialValues: {
      codecommercial: "",
      tiers: "",
      telPortable: "",
      email: "",
      birthday:"",
      password:"",
      datecre:"",

    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {

      try {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('codecommercial', values.codecommercial)
        formData.append('tiers', values.tiers)
        formData.append('email', values.email)
        formData.append('password', values.password)
        formData.append('birthday', values.birthday)      
        formData.append('telPortable', values.telPortable)      
        formData.append('datecre', values.datecre)      
        if (newImage) {
          await compressAndAppendImage(newImage as unknown as File, formData, 'imageFile',0.4)
        }


        const response = await axios.post(`${apiUrl}/update-user/${id}`, formData);
          console.log(formData);
        
        if (response.status === 200) {
          setIsLoading(false)
          toast.success('Compte est modifier avec succès'); 
        }else {
          toast.error('Erreur lors de la modification de Compte');
        }

      } catch {
        toast.error('Erreur lors de la communication avec le serveur');

      }

      resetForm()
    },
  })

  

  

  function generateSlug(inputString: string): string {
    let string = inputString.replace(/&/g, 'et').replace(/'|’/g, '-').replace('$', '');

    const transliterated = slugify(string, {
      lower: true,
      remove: /[^a-zA-Z0-9 -]/g,
    });

    const slug = transliterated.replace(/ /g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

    return slug;
  }


  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setNewImage(e.target.files[0]);
    }
  };



  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#product'
          aria-expanded='true'
          aria-controls='product'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Modifier Un Utilisateur</h3>
          </div>
        </div>

        <div id='product' className='collapse show'>
          <form onSubmit={handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>

              <div className='row mb-6'>
                <div className='col-lg-12'>
                  <div className='row'>

                 
                  

                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nom et Prénom</label>

                      <input
                        type='text'
                        name='firstname'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Nom de categorie'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tiers}
                      />
                      {formik.touched.tiers && formik.errors.tiers && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.tiers}</div>
                        </div>
                      )}
                    </div>


                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Email</label>

                      <input
                        type='text'
                        name='email'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Nom de categorie'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.email}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>telephone</label>

                      <input
                        type='number'
                        name='phoneNumber'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Nom de categorie'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.telPortable}
                      />
                      {formik.touched.telPortable && formik.errors.telPortable && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.telPortable}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>date de naissance</label>
                    <input
                      type='date'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='birthday'
                      {...formik.getFieldProps('datecre')}
                    />
                    {formik.touched.datecre && formik.errors.datecre && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.datecre}</div>
                      </div>
                    )}
                  </div>


                    
                  </div>
                </div>
              </div>

               


                {/* <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Image</label>
                  <input
                    type="file"
                    name="picture"
                    accept=".png, .jpg, .jpeg, .webp"
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target.files && e.target.files[0]) {
                        // Utilisez setFieldValue de Formik pour mettre à jour le champ "picture" avec le fichier sélectionné
                        formik.setFieldValue('picture', e.target.files[0]);
                      }
                    }}
                  />
                  {formik.touched.picture && formik.errors.picture && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.picture}</div>
                    </div>
                  )}
                </div> */}

            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={isloading}>
              {!isloading && 'Save Changes'}
              {isloading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          </form>
        </div>

      </div>






    </>
  )
}

export default UpdateUser