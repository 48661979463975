import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import ListCategory from '../pages/Category/ListCategory'

import AddCategorie from '../pages/Category/AddCategory'
import AddCategory from '../pages/Category/AddCategory'
import UpdateCategory from '../pages/Category/UpdateCategory'
import ListUser from '../pages/User/ListAdmin'
import AddUser from '../pages/User/AddUser'
import UpdateUser from '../pages/User/UpdateUser'
import ListAdmin from '../pages/User/ListAdmin'
import ListClient from '../pages/User/ListClient'
import ListOrder from '../pages/Order/ListOrder'
import AddMarque from '../pages/Marque/AddMarque'
import UpdateMarque from '../pages/Marque/UpdateMarque'
import ListMarque from '../pages/Marque/ListMarque'
import UpdateArticle from '../pages/Article/UpdateArticle'
import ListArticle from '../pages/Article/ListArticle'
import AddSlider from '../pages/Slider/AddSlider'
import ListSlider from '../pages/Slider/ListSlider'
import AddCommercial from '../pages/Commercial/AddCommercial'
import ListCommercial from '../pages/Commercial/ListCommercial'
import UpdateCommercial from '../pages/Commercial/UpdateCommercial'
// import UpdateCategory from '../pages/Category/UpdateCategory'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />

        {/* Marque  */}
        <Route
          path="/admin/Add-Marque"
          element={<AddMarque/>}
        />
        <Route
          path="/admin/update-marque/:id"
          element={<UpdateMarque />}
        />
        <Route
          path="/admin/ListMarque"
          element={<ListMarque />}
        />

        {/* Categoery  */}
        <Route
          path="/admin/Add-Category"
          element={<AddCategory/>}
        />
        <Route
          path="/admin/update-category/:id"
          element={<UpdateCategory />}
        />
        <Route
          path="/admin/ListCategory"
          element={<ListCategory />}
        />

        {/* slider  */}
        <Route
          path="/admin/Add-Slider"
          element={<AddSlider/>}
        />
        <Route
          path="/admin/List-Slider"
          element={<ListSlider/>}
        />
       

        {/* Product  */}

        <Route
          path="/admin/Update-Article/:id"
          element={<UpdateArticle/>}
        />

        <Route
          path="/admin/List-Article"
          element={<ListArticle />}
        />

        <Route
          path="/admin/ListOrder"
          element={<ListOrder />}
        />
        <Route
          path="/admin/update-order/:id"
          element={<ListOrder />}
        />

      {/* utilisateur  */}

        <Route
          path="/admin/List-Admin"
          element={<ListAdmin />}
        />
        <Route
          path="/admin/List-Client"
          element={<ListClient />}
        />
        <Route
          path="/admin/Add-User"
          element={<AddUser />}
        />
        <Route
          path="/admin/Update-User/:id"
          element={<UpdateUser />}
        />
        <Route
          path="/admin/Add-Commercial"
          element={<AddCommercial />}
        />
        <Route
          path="/admin/List-Commercial"
          element={<ListCommercial />}
        />
        <Route
          path="/admin/Update-Commercial"
          element={<UpdateCommercial />}
        />
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
